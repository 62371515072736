import { ref, watch, computed } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useRouter } from "@/@core/utils/utils";

export default function UseLanguageWordList() {
  // Use toast
  const toast = useToast();
  const { t } = useI18nUtils();
  const router = useRouter();

  const refTranslateList = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "key", label: t("Key") },
    { key: "value", label: t("Value") },
  ];
  const perPage = ref(10);
  const totalWord = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refTranslateList.value
      ? refTranslateList.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalWord.value,
    };
  });

  const refetchData = () => {
    refTranslateList.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });
  const fetchWord = (ctx, callback) => {
    instance
      .get(`/parameters/languages/${router.route.value.params.code}`, {
        params: {
          page: currentPage.value,
          search: searchQuery.value,
        },
      })
      .then((response) => {
        //const { count, results } = response.data;
        //totalWord.value = count;
        callback(response.data);
      })
      .catch((err) => {
      });
  };

  return {
    fetchWord,
    tableColumns,
    perPage,
    currentPage,
    totalWord,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTranslateList,
    refetchData,
  };
}

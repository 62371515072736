<template>
  <component is="b-card">
    <b-form class="p-1">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Search')" label-for="name">
            <b-form-input
              id="name"
              v-model="searchQuery"
              debounce="500"
              @input="search_key"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div>
      <form
        name="myForm"
        id="my_Form"
        class="needs-validation"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <div style="display: flex; justify-content: flex-end; margin: 20px 0">
          <b-button
            v-if="code != 'en'"
            id="submit-button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click.prevent="saveLanguage()"
            variant="primary"
          >
            {{ $t("Save Change") }}
          </b-button>
        </div>
        <div
          v-if="code != 'en'"
          class="row"
          v-for="val in words.slice(
            this.perpage * currentPage - this.perpage,
            this.perpage * currentPage
          )"
        >
          <div class="col-md-6 mb-3">
            <label class="form-control" style="background-color: whitesmoke">{{
              val.key
            }}</label>
          </div>
          <div class="col-md-6 mb-3">
            <input
              ref="wordsValue"
              type="text"
              class="form-control words"
              id="wordsValue"
              placeholder=""
              v-model="val.value"
              :name="val.key"
            />
          </div>
        </div>
        <div
          v-if="code == 'en'"
          class="row"
          v-for="val in words.slice(
            this.perpage * currentPage - this.perpage,
            this.perpage * currentPage
          )"
        >
          <div class="col-md-12 mb-3">
            <label class="form-control" style="background-color: whitesmoke">{{
              val.key
            }}</label>
          </div>
        </div>
        <!-- Rest of the form removed to save space   -->
      </form>
      <b-card no-body class="mb-0">
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <b-form-group :label="$t('Number of word')" label-for="per_page">
                <select
                  name="per_page"
                  @change="onSelectChange($event)"
                  class="design"
                >
                  <option value="10" selected="selected">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </b-form-group>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :per-page="this.perpage"
                :total-rows="words.length"
                aria-controls="my_Form"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormTextarea,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import UseLanguageWordList from "./UseLanguageWordList";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Multiselect from "vue-multiselect";
import { useRouter } from "@/@core/utils/utils";
import axios from "axios";
import { json } from "body-parser";
import { integer } from "vee-validate/dist/rules";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    Multiselect,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      code: null,
      searchQuery: null,
    };
  },
  methods: {
    async search_key(searchQuery) {
      try {
        const res = await instance.get(
          `/parameters/languages/${this.$router.currentRoute.params.code}`,
          {
            params: {
              search: searchQuery,
            },
          }
        );
        this.words = res.data;
        this.perpage = 10;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    onSelectChange(event) {
      const res = event.target.value;
      this.perpage = res;
    },
    async loadword() {
      this.code = this.$router.currentRoute.params.code;
      try {
        const res = await instance.get(
          `/parameters/languages/${this.$router.currentRoute.params.code}`,
          {
            params: {
              search: this.searchQuery.value,
            },
          }
        );
        this.words = res.data;
        this.perpage = 10;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async saveLanguage() {
      let form = document.forms["myForm"].getElementsByClassName("words");
      let valueArr = [];

      // getFormValues
      for (var x of this.words) {
        if (form.hasOwnProperty(x.key)) {
          x.value = form[x.key].value;
        }
        valueArr.push({ key: x.key, value: x.value });
      }
      let data = JSON.stringify(valueArr, null, 2);

      try {
        await instance.post(
          `/parameters/languages/${this.$router.currentRoute.params.code}`,
          data
        );
        location.reload();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text:
              err.message ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  created() {
    this.loadword();
  },
  props: {
    words: {
      type: Object,
      //required: true,
    },
    perpage: {
      type: integer,
      //required: true,
    },
  },
  data() {
    return {
      perpage: 10,
      currentPage: 1,
    };
  },

  setup() {
    const {
      fetchWord,
      tableColumns,
      perPage,
      currentPage,
      totalWord,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTranslateList,
      refetchData,
      categorieValue,
      brandValue,
      trancheValue,
      productNameValue,
    } = UseLanguageWordList();

    const isEdit = ref([...Array(perPage.value).keys()].map((e) => false));
    const router = useRouter();
    return {
      fetchWord,
      tableColumns,
      perPage: currentPage,
      totalWord,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTranslateList,
      refetchData,
      // Filter
      avatarText,
      isEdit,
      categorieValue,
      brandValue,
      trancheValue,
      productNameValue,
    };
  },
};
</script>

<style lang="scss" scoped>
select.design {
  width: 71px;
  border: 1px solid #cfcdcd;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  height: 33px;
  border-radius: 9px;
}
.per-page-selector {
  width: 90px;
}
.form-control {
  margin-bottom: -40px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
